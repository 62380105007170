import { buildApiClientFromNextJsContext } from '@/api/apiClient';
import { ApiClientOptions } from '@/types/apiclient';
import { Publication } from '@/types/core/Publication';
import { User } from '@/types/core/User';
import { EditProfileDTO } from '@/types/dto/EditProfileDTO';
import { SearchResult } from '@/types/search/searchresult';
import { ResponseAttributes, buildUrlFromSearch } from './search';

export async function login(
  user: { email: string; password: string },
  options?: ApiClientOptions
): Promise<Response> {
  const apiClient = await buildApiClientFromNextJsContext(options?.context);
  const url = `/api/login`;
  return await apiClient(url, {
    method: 'POST',
    body: JSON.stringify(user),
  });
}

export async function logout(options?: ApiClientOptions): Promise<Response> {
  const apiClient = await buildApiClientFromNextJsContext(options?.context);
  const url = `/api/logout`;
  return await apiClient(url, {
    method: 'POST',
  });
}

export async function fetchColleaguesOfColleagues(
  user: User,
  options?: ApiClientOptions
): Promise<SearchResult<User>> {
  const apiClient = await buildApiClientFromNextJsContext(options?.context);
  const url =
    '/api/users' +
    '?filters[type]=member' +
    '&filters[random]=' +
    '&filters[randomCommonColleagues]=' +
    user.id +
    '&fields[user]=displayName,slug,roles,countCommonColleagues:' +
    user.id +
    '&includes=country,avatar' +
    '&fields[file]=filename' +
    '&fields[country]=name,primaryColor,code' +
    '&items=5';
  const response = await apiClient(url);
  return response.json();
}

export async function fetchCurrentUser(
  responseAttributes: ResponseAttributes,
  options?: ApiClientOptions
): Promise<User> {
  const apiClient = await buildApiClientFromNextJsContext(options?.context);
  const response = await apiClient(
    buildUrlFromSearch('/api/users/me', responseAttributes)
  );
  return response.json();
}

export async function fetchUser(
  responseAttributes: ResponseAttributes,
  userId: string,
  options?: ApiClientOptions
): Promise<User | null> {
  const apiClient = await buildApiClientFromNextJsContext(options?.context);
  const response = await apiClient(
    buildUrlFromSearch(`/api/users/${userId}`, responseAttributes)
  );

  if (!response.ok) {
    return null;
  }
  return response.json();
}

export async function getCurrentUserSuggestions(
  withPublications: boolean,
  withMembers: boolean,
  withGroups: boolean,
  options?: ApiClientOptions
): Promise<Publication[]> {
  const apiClient = await buildApiClientFromNextJsContext(options?.context);
  let includes = [];
  if (withPublications) {
    includes = [
      ...includes,
      'suggestedPublications.image',
      'suggestedPublications.medias.file',
      'suggestedPublications.author.avatar',
      'suggestedPublications.author.country',
      'suggestedPublications.country',
    ];
  }
  if (withMembers) {
    includes = [
      ...includes,
      'suggestedMembers.country',
      'suggestedMembers.avatar',
    ];
  }
  if (withGroups) {
    includes = [...includes, 'suggestedGroups'];
  }

  const result = await apiClient(
    `/api/users/me` +
      '?fields[user]=displayName,slug,roles,jobs,disciplines,countPublications:,isExpert' +
      (includes.length > 0 ? `&includes=${includes.join(',')}` : '') +
      '&fields[publication]=title,slug,abstract,text,countComments,countSelections,countRecommendations,diamond,isSelected,isReported,publishedAt,imageAlt,categories,pinned' +
      '&fields[resource]=subtitle,labelized,categories,requiredCECRLevels' +
      '&fields[news]=newsTypes' +
      '&fields[methodologicalResource]=trainingGoals' +
      '&fields[group]=name,slug,private,target,goal,countPublications,countMembers' +
      '&fields[country]=name' +
      '&fields[media]=title,description,mediaType' +
      '&fields[videoMedia]=url,imagePreview' +
      '&fields[soundMedia]=url' +
      '&fields[linkMedia]=url' +
      '&fields[file]=filename,originalFilename'
  );
  if (result.ok) {
    return result.json().then(user => (user ? user.suggestedPublications : []));
  }
  return [];
}

export async function unfinishedSignUp(
  {
    countryId,
    password,
    email,
    captcha,
  }: {
    countryId: string;
    password: string;
    email: string;
    captcha: number;
  },
  options?: ApiClientOptions
): Promise<Response> {
  const body = JSON.stringify({
    countryId: countryId,
    plainPassword: password,
    email: email,
    captcha: captcha,
  });
  const apiClient = await buildApiClientFromNextJsContext(options?.context);
  const url = `/api/unfinished-signup`;
  return apiClient(url, {
    method: 'POST',
    body: body,
  });
}

export async function completeSignUp(
  signUpId: string,
  userToSignUp: {
    firstname: string;
    lastname: string;
    jobs: any;
    avatar: string | null;
  },
  options?: ApiClientOptions
): Promise<Response> {
  const apiClient = await buildApiClientFromNextJsContext(options?.context);
  const url = `/api/unfinished-signup/${signUpId}/complete`;
  return apiClient(url, {
    method: 'PUT',
    body: JSON.stringify(userToSignUp),
  });
}

export async function inviteColleague(
  user: User,
  options?: ApiClientOptions
): Promise<Response> {
  const apiClient = await buildApiClientFromNextJsContext(options?.context);
  const url = `/api/users/${user.id}/colleague-invitation`;
  return await apiClient(url, {
    method: 'POST',
  });
}

export async function requestResetPassword(
  email: string,
  options?: ApiClientOptions
): Promise<Response> {
  const apiClient = await buildApiClientFromNextJsContext(options?.context);
  const url = `/api/users/requestResetPassword`;
  return await apiClient(url, {
    method: 'POST',
    body: JSON.stringify({ email }),
  });
}

export async function refreshUser(
  options?: ApiClientOptions
): Promise<User | null> {
  const apiClient = await buildApiClientFromNextJsContext(options?.context);
  const url =
    '/api/users/me?' +
    'fields[user]=displayName,firstname,lastname,jobs,roles,email,disciplines,jobs,unreadMessages,unreadAlerts,countColleagues,hasCompletedAllRecommendedFields,emailIFPCommunication,emailNetworkEvents,emailCountryNews,school,schoolType' +
    '&fields[country]=name,code,primaryColor,secondaryColor' +
    '&includes=country,avatar,partnership' +
    '&fields[partnership]=businessSector,activities,otherActivities,name,structureType' +
    '&fields[file]=filename';
  const response = await apiClient(url);
  if (response.status !== 200) {
    return null;
  }
  return response.json();
}

export async function signUpDisciplinesSchool(
  {
    disciplines,
    school,
    schoolType,
  }: {
    disciplines: string[];
    school: string;
    schoolType: string;
  },
  options?: ApiClientOptions
): Promise<Response> {
  const apiClient = await buildApiClientFromNextJsContext(options?.context);
  const url = `/api/signup/disciplines-school`;
  return apiClient(url, {
    method: 'PUT',
    body: JSON.stringify({ disciplines, school, schoolType }),
  });
}

export async function signUpPreferences(
  {
    emailIFPCommunication,
    emailCountryNews,
  }: { emailIFPCommunication: string; emailCountryNews: string },
  options?: ApiClientOptions
): Promise<Response> {
  const apiClient = await buildApiClientFromNextJsContext(options?.context);
  const url = `/api/signup/preferences`;
  return apiClient(url, {
    method: 'PUT',
    body: JSON.stringify({ emailIFPCommunication, emailCountryNews }),
  });
}

export async function fetchColleagues(
  user: User,
  page: number,
  nbPerPage: number,
  orders?: Array<string>,
  options?: ApiClientOptions
): Promise<SearchResult<User>> {
  const apiClient = await buildApiClientFromNextJsContext(options?.context);
  let url =
    '/api/users' +
    `?filters[colleague]=${user.id}` +
    `&fields[user]=displayName,slug,disciplines,jobs,town,roles,colleagueState:${
      user ? user.id : ''
    },countPublications:` +
    '&fields[country]=name,code' +
    '&fields[file]=filename' +
    '&includes=country,avatar' +
    '&countTotalResults=1' +
    `&items=${nbPerPage}` +
    `&page=${page}`;

  if (orders) {
    const joinedOrders = orders.join(',');
    url += `&order=${joinedOrders}`;
  } else {
    url += '&order=-colleagueAt';
  }

  const response = await apiClient(url);
  return response.json();
}

export async function editUserProfile(
  userId: string,
  user: EditProfileDTO,
  options?: ApiClientOptions
): Promise<Response> {
  const apiClient = await buildApiClientFromNextJsContext(options?.context);
  const url = `/api/users/${userId}`;
  return apiClient(url, {
    method: 'PUT',
    body: JSON.stringify(user),
  });
}

export async function deleteUserProfile(
  user: User,
  removePublications: boolean,
  options?: ApiClientOptions
): Promise<Response> {
  const apiClient = await buildApiClientFromNextJsContext(options?.context);
  const url = `/api/users/${user.id}`;
  return apiClient(url, {
    method: 'DELETE',
    body: JSON.stringify({ removePublications }),
  });
}

export async function changeUserPassword(
  user: User,
  {
    oldPlainPassword,
    newPlainPassword,
  }: { oldPlainPassword: string; newPlainPassword: string },
  options?: ApiClientOptions
): Promise<Response> {
  const apiClient = await buildApiClientFromNextJsContext(options?.context);
  const url = `/api/users/${user.id}/change-password`;
  return apiClient(url, {
    method: 'PUT',
    body: JSON.stringify({ oldPlainPassword, newPlainPassword }),
  });
}

export async function changeAlertEmail(
  user: User,
  emailParams: ChangeAlertEmailParametersDTO,
  options?: ApiClientOptions
): Promise<Response> {
  const apiClient = await buildApiClientFromNextJsContext(options?.context);
  const url = `/api/users/${user.id}/change-alert-email-params`;
  return apiClient(url, {
    method: 'PUT',
    body: JSON.stringify(emailParams),
  });
}

export async function fetchAlertEmailForUser(
  user: User,
  options?: ApiClientOptions
): Promise<User> {
  const apiClient = await buildApiClientFromNextJsContext(options?.context);
  const response = await apiClient(
    `/api/users/${user.id}` +
      '?fields[user]=displayName,emailAlerts,emailNetworkEvents,emailIFPCommunication,emailCountryNews,emailOrganiserInfos,emailOrganiserToolbox,autoSubscribeOnNewDiscussionDocument,notifyOnCommentedDiscussion,roles'
  );
  return response.json();
}

export async function fetchEditProfileForUser(
  user: User,
  options?: ApiClientOptions
): Promise<User> {
  const apiClient = await buildApiClientFromNextJsContext(options?.context);
  const response = await apiClient(
    `/api/users/${user.id}` +
      '?fields[user]=roles,emailAlerts,emailNetworkEvents,emailIFPCommunication,emailCountryNews,email,firstname,displayName,lastname,jobs,disciplines,school,schoolType,address,address2,postcode,town,association,tel,skype,publicEmail,introduction,recommendedFieldsCompleted,countPublications:,isExpert' +
      '&includes=websites,avatar,country,partnership' +
      '&fields[userWebsite]=title,url' +
      '&fields[file]=filename,originalFilename' +
      '&fields[partnership]=businessSector,activities,otherActivities,name,structureType' +
      '&fields[country]=name'
  );
  return response.json();
}

export async function dropColleague(
  userId: string,
  options?: ApiClientOptions
): Promise<Response> {
  const apiClient = await buildApiClientFromNextJsContext(options?.context);
  const url = `/api/users/${userId}/drop-colleague`;
  return apiClient(url, {
    method: 'DELETE',
  });
}

export async function disableUser(
  userId: string,
  options?: ApiClientOptions
): Promise<Response> {
  const apiClient = await buildApiClientFromNextJsContext(options?.context);
  const url = `/api/users/${userId}/disable`;
  return apiClient(url, {
    method: 'PATCH',
  });
}

export async function enableUser(
  userId: string,
  options?: ApiClientOptions
): Promise<Response> {
  const apiClient = await buildApiClientFromNextJsContext(options?.context);
  const url = `/api/users/${userId}/enable`;
  return apiClient(url, {
    method: 'PATCH',
  });
}

export async function resetPassword(
  {
    newPassword,
    resetToken,
  }: {
    newPassword: string;
    resetToken: string;
  },
  options?: ApiClientOptions
): Promise<Response> {
  const apiClient = await buildApiClientFromNextJsContext(options?.context);
  const url = `/api/users/resetPassword`;
  return apiClient(url, {
    method: 'POST',
    body: JSON.stringify({ newPassword, resetToken }),
  });
}
