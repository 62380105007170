import React, { ReactNode, useEffect, useMemo } from 'react';
import MetadataComponent from '@/components/app/Metadata';
import Layout from '@components/presentational/Layout';
import { Box, ChakraProvider } from '@chakra-ui/react';
import chakraAppTheme from '../../../../appTheme';
import Footer from '@components/Footer';
import Head from 'next/head';
import { GTMPage, useGoogleTagManager } from '@components/app/hooks/useGTM';
import useUser from '@/hooks/user';
import getConfig from 'next/config';
import { useRouter } from 'next/router';

interface Props {
  metadata?: Metadata;
  children: ReactNode;
  gtmPage?: GTMPage;
}

export interface Metadata {
  title: string;
  description: string;
  breadcrumb?: { id: string | null; name: string }[];
  keywords?: string[];
  isDisconnectedHomepage?: boolean;
  event?: any;
  resource?: any;
  news?: any;
  article?: any;
  group?: any;
  user?: any;
}

function FrontLayout({ metadata, children, gtmPage }: Props) {
  const { sendDataToGTM } = useGoogleTagManager();
  const currentUser = useUser();
  const { asPath } = useRouter();
  const host = getConfig().publicRuntimeConfig.HOST;

  const currentUrl = useMemo(() => {
    return `${host}${asPath}`;
  }, [host, asPath]);

  const searchAction = {
    '@context': 'https://schema.org',
    '@type': 'WebSite',
    url: host,
    potentialAction: {
      '@type': 'SearchAction',
      target: {
        '@type': 'EntryPoint',
        urlTemplate: `${host}/recherche?s={search_term_string}`,
      },
      'query-input': 'required name=search_term_string',
    },
  };

  useEffect(() => {
    if (!gtmPage) return;
    sendDataToGTM({
      data: {
        event: 'generic',
        user: {
          user_id: currentUser?.id,
          user_email: currentUser?.email,
          user_country: currentUser?.country?.name,
        },
        page: gtmPage,
      },
    });
  }, []);

  return (
    <ChakraProvider theme={chakraAppTheme}>
      <Head>
        <meta
          name="viewport"
          content="width=device-width, initial-scale=1.0"
          key="viewport"
        />
        <script
          type="application/ld+json"
          dangerouslySetInnerHTML={{
            __html: JSON.stringify(searchAction),
          }}
        />
        <link rel="canonical" href={currentUrl} />
      </Head>
      <Layout>
        <Box>{children}</Box>
        <Footer />
      </Layout>
    </ChakraProvider>
  );
}

export default FrontLayout;
