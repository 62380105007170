import {
  Flex,
  FormControl,
  FormErrorMessage,
  FormHelperText,
  FormLabel,
  VisuallyHidden,
} from '@chakra-ui/react';

export default function FormElement({
  children,
  label = null,
  htmlFor = null,
  errorMessage = null,
  isDisabled = null,
  isInvalid = null,
  isRequired = null,
  isInline = null,
  isReverted = null,
  formHelperText = null,
  isLabelVisuallyHidden = false,
  isRequiredIndicatorNotDisplayed = false,
  isHomePageLabel = false,
}) {
  return (
    <FormControl
      isDisabled={isDisabled}
      isInvalid={isInvalid}
      isRequired={isRequired}
    >
      <Flex
        display={!isInline ? 'revert' : null}
        alignItems={isInline ? 'center' : null}
        wrap={isInline ? 'wrap' : null}
        direction={isReverted ? 'row-reverse' : 'inherit'}
        justifyContent={isReverted ? 'flex-end' : 'normal'}
        gap={isReverted ? '1rem' : '0'}
      >
        {label &&
          (isLabelVisuallyHidden ? (
            <VisuallyHidden>
              <FormLabel
                htmlFor={htmlFor || undefined}
                mb={{ sm: isInline ? '0.25rem' : '0.5rem' }}
                requiredIndicator={
                  isRequiredIndicatorNotDisplayed ? null : undefined
                }
                fontWeight={isHomePageLabel && '600'}
                fontSize={isHomePageLabel && '1.125rem'}
              >
                {label}
              </FormLabel>
            </VisuallyHidden>
          ) : (
            <FormLabel
              htmlFor={htmlFor || undefined}
              mb={{ sm: isInline ? '0.25rem' : '0.5rem' }}
              requiredIndicator={
                isRequiredIndicatorNotDisplayed ? null : undefined
              }
              fontWeight={isHomePageLabel && '600'}
              fontSize={isHomePageLabel && '1.125rem'}
            >
              {label}
            </FormLabel>
          ))}
        {children}
        {isInvalid && (
          <FormErrorMessage width={isInline ? '100%' : null}>
            {errorMessage}
          </FormErrorMessage>
        )}
      </Flex>
      {formHelperText && <FormHelperText>{formHelperText}</FormHelperText>}
    </FormControl>
  );
}
